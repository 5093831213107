import { ReactComponent as Logo } from '../../assets/svgs/kilburn-logo.svg'
import { ReactComponent as Hamburger } from '../../assets/svgs/hamburger.svg'
import { ReactComponent as DropdownArrow } from '../../assets/svgs/dropdown-arrow.svg'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import * as styles from './Navigation.module.scss'
import PageContainer from '../../layouts/PageContainer/PageContainer'
import { useState } from 'react'
import { gaLinkEvent } from '../../utils/trackingEvents/trackingEvents'
import { useContentful } from '../../hooks/useContentful'

export const isActiveItem = (item, pathName) => {
  return (
    item.fields.url &&
    item.fields.url?.replace('/', '') === pathName?.replace('/', '') &&
    !item.fields.url.includes('/coming-soon')
  )
}

export const getActiveUrl = (item, pathName) => {
  let newItem = isActiveItem(item, pathName)

  if (item.sys?.contentType?.sys.id === 'navDropdown') {
    const matchingChild = item.fields.links.find((subItem) =>
      isActiveItem(subItem, pathName)
    )

    if (matchingChild) {
      newItem = true
    }
  }

  return newItem
    ? { ...item, fields: { ...item.fields, isCurrentPage: true } }
    : item
}

const NavigationDropdown = ({
  children,
  id,
  text,
  onClick,
  isActive,
  isCurrentPage
}) => {
  const handleOnClick = (event) => {
    event.preventDefault()
    onClick(id)
    gaLinkEvent('Navigation', text)
  }

  return (
    <div className={styles.navigationDropdown}>
      <a
        href={`#${id}`}
        className={classNames(
          styles.navigationDropdownLink,
          isActive ? styles.navigationDropdownLinkSelected : undefined,
          isCurrentPage ? styles.navigationDropdownLinkActive : undefined
        )}
        onClick={handleOnClick}
        onBlur={() => {
          onClick(null)
        }}>
        {text}
        <DropdownArrow className={styles.navigationDropdownArrow} />
      </a>
      <div className={styles.navigationDropdownContainer} id={id}>
        {children}
      </div>
    </div>
  )
}

const NavItems = ({
  item,
  handleLogoutClick,
  activeDropdown,
  setActiveDropdown
}) => {
  const onClick = () => {
    gaLinkEvent('Navigation', item.children)
    if (item.key === 'logout') {
      handleLogoutClick()
    }
  }

  if (item.key === 'logout' || item.target) {
    return (
      <li key={item.key}>
        <a
          href={item.href || '#'}
          onClick={onClick}
          className={styles.navigationDropdownLink}
          target={item.target}
          rel={item.rel}>
          {item.children}
        </a>
      </li>
    )
  }

  if (!item.subItems) {
    return (
      <li key={item.key}>
        <Link
          to={item.href}
          className={classNames(
            styles.navigationDropdownLink,
            item.isCurrentPage ? styles.navigationDropdownLinkActive : undefined
          )}
          onClick={onClick}
          aria-current={item.isCurrentPage ? 'true' : undefined}>
          {item.children}
        </Link>
      </li>
    )
  }

  return (
    <li key={item.key}>
      <NavigationDropdown
        text={item.title}
        id={`nav-dropdown-${item.key}`}
        isActive={activeDropdown === `nav-dropdown-${item.key}`}
        isCurrentPage={item.isCurrentPage}
        onClick={setActiveDropdown}>
        <ul>
          {item.subItems.map((subItem) => {
            if (subItem.target) {
              return (
                <li key={subItem.key}>
                  <a
                    href={subItem.to || '#'}
                    target={subItem.target}
                    rel={subItem.rel}
                    onClick={() => gaLinkEvent('Navigation', subItem.children)}>
                    {subItem.children}
                  </a>
                </li>
              )
            }

            return (
              <li key={subItem.key}>
                <Link
                  {...subItem}
                  onClick={() => gaLinkEvent('Navigation', subItem.children)}
                />
              </li>
            )
          })}
        </ul>
      </NavigationDropdown>
    </li>
  )
}

const Navigation = ({ handleLogoutClick, pathname }) => {
  const { contentfulData, loaded } = useContentful('header')
  const [showNav, setShowNav] = useState(false)
  const [activeDropdown, setActiveDropdown] = useState(null)

  if (!loaded) {
    return null
  }

  const { navItems: items } = contentfulData.items[0].fields

  const newNavArray = [
    ...items,
    {
      sys: {
        id: 'logout',
        contentType: {
          sys: {
            id: 'link'
          }
        }
      },
      fields: {
        url: '#',
        text: 'Logout',
        isExternal: false
      }
    }
  ].map((navItem) => getActiveUrl(navItem, pathname))

  return (
    <nav className={styles.navigation}>
      <PageContainer className={styles.navigationContainer}>
        <ul>
          <li>
            <Link
              to="/"
              className={styles.navigationLogo}
              aria-label="Kilburn Hub">
              <Logo />
            </Link>
          </li>
          <li>
            <button
              type="button"
              aria-controls="main-nav"
              aria-expanded={showNav}
              aria-label="Open main navigation"
              className={styles.navigationMobileCta}
              onClick={() => setShowNav(!showNav)}>
              <Hamburger />
            </button>
          </li>
        </ul>
        <ul
          id="main-nav"
          className={classNames(
            styles.navigationMainMenu,
            showNav ? styles.navigationMainMenuShow : undefined
          )}>
          {newNavArray.map(({ fields: navItem, sys }) => {
            if (sys.contentType?.sys.id === 'link') {
              return (
                <NavItems
                  key={sys.id}
                  item={{
                    key: sys.id,
                    children: navItem.text,
                    href: navItem.url,
                    target: navItem.isExternal ? '_blank' : undefined,
                    rel: navItem.isExternal ? 'noreferrer' : undefined,
                    isCurrentPage: navItem.isCurrentPage
                  }}
                  handleLogoutClick={handleLogoutClick}
                  activeDropdown={activeDropdown}
                  setActiveDropdown={setActiveDropdown}
                />
              )
            }

            if (navItem.links?.length > 0) {
              return (
                <NavItems
                  key={sys.id}
                  item={{
                    isCurrentPage: navItem.isCurrentPage,
                    title: navItem.title,
                    key: sys.id,
                    subItems: navItem.links.map(
                      ({ fields: navSubItem, sys }) => ({
                        key: sys.id,
                        to: navSubItem.url,
                        children: navSubItem.text,
                        target: navSubItem.isExternal ? '_blank' : undefined,
                        rel: navSubItem.isExternal ? 'noreferrer' : undefined
                      })
                    )
                  }}
                  activeDropdown={activeDropdown}
                  setActiveDropdown={setActiveDropdown}
                  handleLogoutClick={handleLogoutClick}
                />
              )
            }

            return null
          })}
        </ul>
      </PageContainer>
    </nav>
  )
}

export default Navigation
