import { getCareerResponse } from './getCareerResponse'

export const formatContentfulData = (type, result) => {
  let data = result

  switch (type) {
    case 'clients':
      data = result.items.map((val) => ({
        clientId: val.fields.clientId,
        clientName: val.fields.clientName,
        isActive: val.fields.isActive,
        image: 'https:' + val.fields.logo.fields.file.url,
        about: val.fields.about,
        difference: val.fields.difference,
        onboarding: val.fields.onboarding,
        links: val.fields.links,
        videoUrl: val.fields.videoUrl
      }))
      break
    case 'fiveUpdate':
      data = result.items.map((val) => ({
        author: val.fields.author,
        client: val.fields.client,
        title: val.fields.title,
        image: 'https:' + val.fields.image.fields.file.url,
        postData: val.fields.fiveReportPost
      }))
      break
    case 'navLinks':
      data = result.items.map((val) => ({
        ctaLink: val.fields.ctaLink,
        ctaText: val.fields.ctaText,
        dropdownLinks: val.fields.dropdownLinks,
        orderNumber: val.fields.orderNumber
      }))
      break
    case 'navbarThemeData':
      data = result.items.map((val) => ({
        logo: val.fields.logo,
        navBrand: val.fields.navBrand,
        navTheme: val.fields.navTheme,
        themeTitle: val.fields.themeTitle
      }))
      break
    case 'posts':
      data = result.items.map((val) => ({
        title: val.fields.title,
        description: val.fields.description,
        image: val.fields.image.fields.file.url,
        textColour: val.fields.textColour
      }))
      break
    case 'blogPost':
      data = result.items.map((val) => ({
        title: val.fields.title,
        author: val.fields.author,
        dateCreated: val.fields.dateCreated,
        image: 'https:' + val.fields.image.fields.file.url,
        summary: val.fields.summary,
        content: val.fields.content
      }))
      break
    case 'howToGuides':
      data = result.items.map((val) => ({
        heading: val.fields.guideHeading,
        description: val.fields.guideDescription,
        url: val.fields.guideUrl,
        type: val.fields.type,
        appearOnHomepage: val.fields.appearOnHomepage,
        icon: val.fields.guideIcon
      }))
      break
    case 'salaryBand':
      data = result.items.map((val) => ({
        level: val.fields.level,
        position: val.fields.positionWithinLevel,
        salary: val.fields.salary,
        increase: val.fields.increase
      }))
      break
    case 'clubHousePage':
      data = result.items.map((val) => ({
        pageTitle: val.fields.pageTitle,
        aboutClubHouse: val.fields.aboutClubHouse,
        appDownload: val.fields.appDownload,
        namesake: val.fields.namesake,
        faqTitle: val.fields.faqTitle,
        faqs: val.fields.faqs
      }))
      break
    case 'glossaryPage':
      data = result.items[0].fields.glossaryItems.map((val) => ({
        letter: val.fields.letter,
        word: val.fields.word,
        description: val.fields.description
      }))
      break
    case 'currentClientPage':
    case 'pastClientPage':
      var filterResult = [result.items[0].fields.clients]
      data = filterResult[0].map((val) => ({
        clientName: val.fields.title,
        logo: val.fields.logo,
        overview: val.fields.overview,
        projects: val.fields.projects,
        numberOfAndis: val.fields.numberOfAndis,
        projectLead: val.fields.projectLead,
        sdmsdl: val.fields.sdmsdl
      }))
      break
    case 'careerPage':
      data = getCareerResponse(result.items[0].fields)
      break
    default:
      break
  }

  return data
}
