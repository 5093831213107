const SCOPES = 'openid profile email'
const REDIRECT_URI = `${window.location.origin}/implicit/callback`

export const config = {
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  issuer: process.env.REACT_APP_OKTA_ISSUER_URI,
  redirectUri: REDIRECT_URI,
  scopes: SCOPES.split(/\s+/),
  pkce: true
}
