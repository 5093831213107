import classNames from 'classnames'
import * as styles from './Heading.module.scss'

const Heading = ({
  type = 'h2',
  headingSize,
  className,
  children,
  ...rest
}) => {
  const HtmlElement = type
  return (
    <HtmlElement
      className={classNames(
        styles.heading,
        styles[`heading${(headingSize || type).toUpperCase()}`],
        className
      )}
      {...rest}>
      {children}
    </HtmlElement>
  )
}

export default Heading
