import { createClient } from 'contentful'

const params = new URL(location.href).searchParams
const isPreview = params.get('preview')

export const getContentFulEntries = async (
  contentFulOptions = {},
  spaceId = process.env.REACT_APP_SPACE_ID,
  accessToken = isPreview === 'true'
    ? process.env.REACT_APP_PREVIEW_TOKEN
    : process.env.REACT_APP_ACCESS_TOKEN
) => {
  const SPACE_ID = spaceId
  const ACCESS_TOKEN = accessToken

  if (!SPACE_ID || !ACCESS_TOKEN) {
    console.log('please configure .env file')
  }

  const contentful = createClient({
    host:
      isPreview === 'true' ? 'preview.contentful.com' : 'cdn.contentful.com',
    space: SPACE_ID,
    accessToken: ACCESS_TOKEN
  })

  const result = await contentful.getEntries({
    ...contentFulOptions,
    limit: 500
  })

  return result
}
