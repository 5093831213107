import PageContainer from '../../layouts/PageContainer/PageContainer'
import RegularLayout from '../../layouts/Regular/Regular'
import Text from '../../components/Text/Text'

const ErrorPage = () => (
  <RegularLayout>
    <PageContainer title="Something went wrong on our end">
      <Text>
        Please contact the slack channel #innovation-kilburn-hub if you see this
        page containing some information about how you created the error so we
        can replicate the issue.
      </Text>
    </PageContainer>
  </RegularLayout>
)

export default ErrorPage
