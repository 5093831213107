import { useContentful } from '../../hooks/useContentful'
import * as styles from './Footer.module.scss'
import { gaLinkEvent } from '../../utils/trackingEvents/trackingEvents'
import { ReactComponent as Logo } from '../../assets/svgs/kilburn-logo-white.svg'
import { Link } from 'react-router-dom'
import Text from '../../components/Text/Text'

const Footer = () => {
  const { contentfulData, loaded } = useContentful('footer')

  const handleClick = (label) => gaLinkEvent('Footer Navigation', label)

  if (!loaded) {
    return null
  }
  const footerData = contentfulData.items[0].fields
  return (
    <footer className={styles.footer}>
      <div className={styles.footerInnerContainer}>
        <div>
          <Logo width="229" height="49" />
          <Text className={styles.footerNote}>{footerData.slogan}</Text>
        </div>

        <nav className={styles.footerColumnContainer}>
          {footerData.footerColumns.map((footerColumn) => (
            <div className={styles.footerColumn} key={footerColumn.sys.id}>
              <div>
                <Text className={styles.footerSubTitle}>
                  {footerColumn.fields.title}
                </Text>
                <ul className={styles.footerLinkList}>
                  {footerColumn.fields.links.map((link) => {
                    if (link.fields.isExternal) {
                      return (
                        <li key={link.sys.id}>
                          <a
                            href={link.fields.url}
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => handleClick(link.fields.text)}>
                            {link.fields.text}
                          </a>
                        </li>
                      )
                    }
                    return (
                      <li key={link.sys.id}>
                        <Link
                          to={link.fields.url}
                          onClick={() => handleClick(link.fields.text)}>
                          {link.fields.text}
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          ))}
        </nav>
      </div>
    </footer>
  )
}

export default Footer
