import { useEffect, Suspense, lazy } from 'react'
import { pageview } from 'react-ga'
import './style/color.scss'
import './style/fonts.scss'
import './App.scss'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { LoginCallback, SecureRoute, Security } from '@okta/okta-react'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { config } from './oktaConfig'
import ContentfulProvider from './context/ContentfulProvider/ContentfulProvider'

const Page = lazy(() => import('./pages/Page/Page'))
const App = () => {
  const history = useHistory()
  const location = useLocation()
  const auth = new OktaAuth(config)
  const originalUri = async (auth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin))
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    pageview(location.pathname + location.search)
  }, [location])

  return (
    <ContentfulProvider>
      <Security oktaAuth={auth} restoreOriginalUri={originalUri}>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route exact path="/implicit/callback" component={LoginCallback} />
            <SecureRoute path="/:slug" component={Page} />
            <SecureRoute path="*" component={Page} />
          </Switch>
        </Suspense>
      </Security>
    </ContentfulProvider>
  )
}

export default App
