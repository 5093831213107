import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { initialize } from 'react-ga'
import { BrowserRouter as Router } from 'react-router-dom'
import ErrorPage from './pages/ErrorPage/ErrorPage'
import { Provider, ErrorBoundary } from '@rollbar/react'

initialize(process.env.REACT_APP_GOOGLE_ANALYTIC_TRACKING_ID)

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.NODE_ENV
  }
}

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Provider config={rollbarConfig}>
        <ErrorBoundary fallbackUI={ErrorPage}>
          <App />
        </ErrorBoundary>
      </Provider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)
