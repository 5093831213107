import { useOktaAuth } from '@okta/okta-react'
import Navigation from '../Navigation/Navigation'

const Header = ({ pathname }) => {
  const auth = useOktaAuth()

  const handleLogoutClick = async () => {
    auth.oktaAuth.tokenManager.clear()
    window.location.href = `https://and-digital.okta.com/app/UserHome`
  }

  return (
    <Navigation handleLogoutClick={handleLogoutClick} pathname={pathname} />
  )
}

// const Header2 = () => <>Header</>

export default Header
