const getImageAndTextSection = (section) => {
  return {
    type: 'imageAndText',
    title: section.title,
    image: 'https:' + section.image.fields.file.url,
    imageDisplayLeft: section.imageDisplayLeft,
    linkDisplayText: section.linkDisplayText,
    linkUrl: section.linkUrl,
    contents: section.text.content.map(({ content: items, nodeType }) => ({
      nodeType,
      nodes: items.map(({ nodeType, value: text }) => ({
        nodeType,
        text
      }))
    }))
  }
}

const getCareerSection = (section, { id }) => {
  if (id === 'imageAndText') return getImageAndTextSection(section)

  return {
    type: id,
    ...section
  }
}

export const getCareerResponse = ({
  title,
  introText,
  introImage,
  sections
}) => {
  return {
    title: title,
    Description: introText,
    image: 'https:' + introImage.fields.file.url,
    sections: sections.map(({ fields: section, sys }) =>
      getCareerSection(section, sys.contentType.sys)
    )
  }
}
