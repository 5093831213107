import classNames from 'classnames'
import BarLoader from 'react-spinners/BarLoader'
import Heading from '../../components/Heading/Heading'
import * as styles from './PageContainer.module.scss'

const PageContainer = ({
  title,
  children,
  className,
  showLoadingState = false,
  isThin = false,
  hasDivider = false
}) => (
  <>
    <div
      className={classNames(
        styles.pageContainer,
        isThin ? styles.pageContainerThin : undefined,
        className
      )}>
      {showLoadingState && (
        <div className={styles.pageContainerLoader}>
          <BarLoader color={'#e62139'} width={'30vw'} />
        </div>
      )}
      {!showLoadingState && title && (
        <Heading type="h1" className={styles.pageContainerHeader}>
          {title}
        </Heading>
      )}
      {!showLoadingState && children}
    </div>
    {hasDivider && !showLoadingState && <hr className={styles.pageDivider} />}
  </>
)

export default PageContainer
