import classNames from 'classnames'
import * as styles from './Text.module.scss'

const Text = ({
  element: HtmlEl = 'p',
  fontSize,
  fontWeight,
  toUpperCase,
  children,
  className,
  ...rest
}) => {
  const classes = classNames(
    styles.text,
    fontSize ? styles[`text${fontSize.toUpperCase()}`] : undefined,
    fontWeight ? styles[`text${fontWeight.toUpperCase()}`] : undefined,
    toUpperCase ? styles.textCaps : undefined,
    className
  )

  return (
    <HtmlEl {...rest} className={classes}>
      {children}
    </HtmlEl>
  )
}

export default Text
