import { useContext } from 'react'
import { ContentFulContext } from '../context/ContentfulProvider/ContentfulProvider'
import { formatContentfulData } from '../utils/contentful/formatContentfulData'

export const useContentful = (type) => {
  const context = useContext(ContentFulContext)

  if (!context) {
    throw Error(
      'You need to have ContentProvider as a parent to use the useContentful hook'
    )
  }

  if (context.loaded) {
    const contentTypes = context.contentfulData.filter((item) => {
      return item.sys.contentType.sys.id === type
    })

    return {
      contentfulData: formatContentfulData(type, { items: contentTypes }),
      loaded: context.loaded
    }
  }

  return context
}
