import { createContext, useEffect, useReducer } from 'react'
import { getContentFulEntries } from '../../utils/contentful/getAllContent'

export const ContentFulContext = createContext()

const contentfulReducer = (_, action) => {
  switch (action.type) {
    case 'RECIEVED_CONTENTFUL_DATA':
      return {
        loaded: true,
        contentfulData: action.payload.items
      }

    case 'ERROR_CONTENTFUL_DATA':
      return {
        loaded: false,
        contentfulData: []
      }
    default:
      throw new Error('Unknown reducer action')
  }
}

const getContentfulData = async (dispatch) => {
  try {
    const data = await getContentFulEntries()

    dispatch({
      type: 'RECIEVED_CONTENTFUL_DATA',
      payload: data
    })
  } catch (e) {
    console.error(e)
    dispatch({
      type: 'ERROR_CONTENTFUL_DATA'
    })
  }
}

const ContentfulProvider = ({ children }) => {
  const [state, dispatch] = useReducer(contentfulReducer, {
    contentfulData: [],
    loaded: false
  })

  useEffect(() => {
    getContentfulData(dispatch)
  }, [])

  return (
    <ContentFulContext.Provider value={state}>
      {children}
    </ContentFulContext.Provider>
  )
}

export default ContentfulProvider
