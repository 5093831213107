import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'

const RegularLayout = ({ children, pathname }) => {
  return (
    <>
      <Header pathname={pathname} />
      <main>{children}</main>
      <Footer />
    </>
  )
}

export default RegularLayout
